<template>
  <div>

    <div class="row" v-permission="['contract.create','contract.edit']">
      <div class="col-md-12">



        <KTPortlet
            v-bind="{
            }"
            v-bind:class="{ 'kt-spinner-f': loading }"
        >
          <template v-slot:title>
            <h4 class="">Password Scaduta</h4>
          </template>
          <template v-slot:body>
            <p>La password in uso è scaduta, è necessario impostarne una nuova.</p>
            <!--                        <b-form-group-->
            <!--                                label-cols="4"-->
            <!--                                label-cols-lg="2"-->
            <!--                                label-size="sm"-->
            <!--                                label="Small"-->
            <!--                                label-for="input-sm"-->
            <!--                        >-->
            <!--                            <b-form-input id="input-sm" size="sm"></b-form-input>-->
            <!--                        </b-form-group>-->
            <b-form @submit="onSubmit" @reset="onReset">
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Password attuale"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.current_password"
                    type="password"
                    required
                    placeholder="Inserisci la password attuale"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Nuova password"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.new_password"
                    type="password"
                    required
                    placeholder="Inserisci la nuova password"
                ></b-form-input>
                <p class="text-danger" v-if="form.current_password && form.new_password==form.current_password">Attenzione: la password deve essere diversa da quella attuale</p>
              </b-form-group>
              <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label="Conferma password"
                  label-for="label"
              >
                <b-form-input
                    id="label"
                    v-model="form.new_password_2"
                    type="password"
                    required
                    placeholder="Inserisci nuovamente la nuova password"
                ></b-form-input>
                <p class="text-danger" v-if="form.new_password_2 && form.new_password!=form.new_password_2">Attenzione: le password non corrispondono</p>
              </b-form-group>
              <b-button class="pull-right" type="submit" variant="primary">Salva</b-button>
            </b-form>
          </template>
        </KTPortlet>
        <!--end:: Widgets/Activity-->
      </div>

    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "../../../../store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import UserService from "../../../../common/user.service";
import ApiService from "../../../../common/api.service";
import Vue from "vue";
import {VERIFY_AUTH} from "@/store/auth.module";

export default {
  data() {
    return {
      loading:false,
      formoptions:{
      },
      form: {
      },

    }
  },
  components: {
    KTPortlet
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Modifica passowrd' }
    ]);
  },

  created () {
  },
  methods:{
    onSubmit(evt) {
      evt.preventDefault()
      if(this.form.new_password==this.form.current_password){
        return;
      }
      if(this.form.new_password!=this.form.new_password_2){
        return;
      }
      //alert(JSON.stringify(this.form))
      this.loading=true;
      let datasend = {};
      datasend.old_password = this.form.current_password;
      datasend.new_password = this.form.new_password;
      ApiService.post('/users/me/password',datasend)
          .then(({data}) => {
            this.$store
                .dispatch(VERIFY_AUTH, {})
                .then((data) => {
                  this.$nextTick(() => {
                    this.loading = false;
                    Vue.customNotifySuccess('Salvato');
                    this.$router.push({ name: "dashboard"});
                  });
                });

          })
          .catch(({response}) => {
            this.loading = false;
            Vue.customNotifyError('Errore',response.data&&response.data.errors?response.data.errors.join(', '):'Si prega di riprovare');
          });
    },
    onReset(evt) {
      this.$router.go(-1);

    },
  }
};
</script>
